import 'stylesheets/metros_organic_deferred.css';
import 'common';
import 'components/hamburger_mobile_menu';
import onFirst from 'utils/on_first';

onFirst(document.getElementById('address_form_address_string'), ['click'], 'metroRedesign', () => {
  import(/* webpackChunkName: "SimpleAddressSearchForm" */ 'simple_address_search_form').then(
    module => {
      new module.SimpleAddressSearchForm().mount();
    }
  );
});

onFirst(
  document.getElementById('address_form_bottom_address_string'),
  ['click'],
  'metroRedesign',
  () => {
    import(/* webpackChunkName: "SimpleAddressSearchForm" */ 'simple_address_search_form').then(
      module => {
        new module.SimpleAddressSearchForm().mount();
      }
    );
  }
);

onFirst(document, ['scroll', 'click'], 'liberty', () => {
  import(/* webpackChunkName: "Liberty" */ 'liberty').then(module => new module.Liberty().mount());
});

if (document.getElementById('location-icon')) {
  import(
    /* webpackChunkName: "setupLocationIconDummyTracking" */ '../setup_location_icon_dummy_tracking'
  ).then(module => module.setupLocationIconDummyTracking());
}
